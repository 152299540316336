var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[`row-for-product`, _vm.driverViewClass]},[_c('div',{staticClass:"row-data-block"},[(
        _vm.items[_vm.num.name] &&
        _vm.items[_vm.num.name]['total_count'] > 0
      )?_c('ProgressVue',{key:1,attrs:{"progressColor":_vm.wellLeveraged,"noReview":false,"value":Number(_vm.items[_vm.num.name]['c_sat_avg']).toFixed(1),"max":5,"pointer":_vm.showPointer,"total":_vm.total},on:{"onChange":function($event){return _vm.onChange(_vm.num.name)}}}):_c('ProgressVue',{key:2,attrs:{"noReview":true,"value":0,"max":0,"pointer":_vm.showPointer,"total":_vm.total}})],1),(
        _vm.items[_vm.num.name] &&
        _vm.items[_vm.num.name]['total_count'] > 0
      )?_c('div',{staticClass:"row-data-block-small"},[_c('div',{staticClass:"importance-style"},[(_vm.driverViewClass === 'likeView')?_c('ul',[_c('div',{staticClass:"like-dislike"},[_c('div',{staticClass:"like-bar",on:{"click":function($event){return _vm.onChange(_vm.num.name,[{name: 'Like',id: 'POSITIVE'}])}}},[_c('p',[_vm._v(_vm._s(Math.round(_vm.items[_vm.num.name]['like']*100)+'%'))]),_c('b-progress',{staticClass:"like-progress",attrs:{"value":_vm.items[_vm.num.name]['like']*100}})],1),_c('div',{staticClass:"dislike-bar",on:{"click":function($event){return _vm.onChange(_vm.num.name,[{name: 'Dislike',id: 'NEGATIVE'}])}}},[_c('p',[_vm._v(_vm._s(Math.round(_vm.items[_vm.num.name]['dislike']*100)+'%'))]),_c('b-progress',{staticClass:"dislike-progress",attrs:{"value":_vm.items[_vm.num.name]['dislike']*100}})],1)])]):_vm._e(),(_vm.driverViewClass !== 'likeView')?_c('ul',{on:{"mouseenter":function($event){return _vm.showData(_vm.name, _vm.num.name)},"mouseleave":function($event){return _vm.showData('', '')}}},[_vm._v(" "+_vm._s(Number(_vm.items[_vm.num.name]['total_count']))+" ")]):_vm._e()])]):_c('div',{staticClass:"row-data-block-small"},[_c('div',{staticClass:"importance-style"},[_c('ul',[_vm._v(" "+_vm._s('N/A')+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }