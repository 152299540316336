<template>
  <div>
    <div class="esat-options" v-if="!noData">
      <div v-if="esatDriverSummary.length" class="toggle-button">
        <div class="option-cover">
          <label for="showTotal" class="custom-checkbox">
            <input type="checkbox" name="showTotal" id="showTotal" @click="toggleShowTotal" :checked="showTotal">
            <div class="checkmark"></div>
            <p>Show Company Total</p>
          </label>
        </div>
      </div>
      <div v-if="esatDriverSummary.length" class="toggle-button">
        <dropdown-wrap @onClickOutside="onClickOutside">
          <div class="option-cover">
              <b-button @click="openDriverViewDropdown" class="driver-display-button">
                  <div class="inside-sort-button inside">
                  <p>{{getName}}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
                  </div>
              </b-button>
              <div v-if="dropdownOpen" class="sort-dropdown">
                  <p class="option-header">Key Attributes:</p>
                  <div class="sort-options">
                    <div class="default-sort"  @click.prevent="onChangeOption(0)">
                      <label for="rating" class="custom-radio">
                        <input type="radio" name="displayView" id="rating" :checked="localSelected == 0">
                        <div class="radio-checkmark"></div>
                        <p>By Ratings</p>
                      </label>
                    </div>
                    <div class="default-sort"  @click.prevent="onChangeOption(1)">
                      <label for="reaction" class="custom-radio">
                        <input type="radio" name="displayView" id="reaction" :checked="localSelected == 1">
                        <div class="radio-checkmark"></div>
                        <p>By Reactions</p>
                      </label>
                    </div>
                  </div>
              </div>
          </div>
        </dropdown-wrap>
      </div>
      <div v-if="esatDriverSummary.length" class="esat-sort">
        <div class="esat-sort-inside">
          <global-sort-vue
            :selected="sort"
            :showSortBy="false"
            :options="sortOptions"
            :csatPage="true"
            @onChange="onChangeSelection" />
        </div>
      </div>
    </div>
    <div class="main-card" v-if="esatData.length">
      <div v-if="!noData" class="line-separator-copy"></div>
      <div v-if="!noData" class="custom-table-container-block-2">
        <table class="esat-table">
          <thead ref="esatTableHeader">
            <tr>
              <th class="filter">
                {{ currentModule === 'voc' ? 'CORE CAPABILITIES' : 'KEY ATTRIBUTES' }}
                <img src="../../../assets/info-new.svg" alt="" class="info-icon" @mouseenter="tooltip($event, tooltipData, 'in')" @mouseleave="tooltip($event, tooltipData, 'out')"
                @mousemove="tooltip($event, tooltipData, 'in')" />
              </th>
              <th class="filter" v-if="showTotal">ALL COMPANY TOTAL</th>
              <th
                v-for="companyName of sortedCompaniesVoe"
                :key="companyName.id"
                :class="driverViewClass">
                <img
                  v-if="
                    (user_preference_mode == 'dark' &&
                      companyName.properties &&
                      companyName.properties.logo_dark) ||
                    (user_preference_mode == 'light' &&
                      companyName.properties &&
                      companyName.properties.logo_light)
                  "
                  :src="
                    user_preference_mode == 'dark'
                      ? companyName.properties.logo_dark
                      : companyName.properties.logo_light
                  "
                  :alt="companyName.name"
                  :title="companyName.name"
                  class="company-logo" />
                <span v-else class="company-name">{{ companyName.name }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(name, index) of esatData">
              <tr :key="'A' + index">
                <td
                  class="row-header tooltip-wrapper"
                  :class="[name.sub_category.length ? 'expand-button has-text-weight-bold' : '']"
                  :helpText="name.helpText"
                  @mouseenter="showTooltip"
                  @mousemove="showTooltip"
                  @mouseleave="hideTooltip"
                  @click="toggleRows(name.topic_id)">
                  {{ name.topic }}
                  <span class="carret" v-if="name.sub_category.length">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" :class="[isExpanded(name.topic_id) ? 'angle-down' : 'angle-right']" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#7f8fa4" d="M6.718 3.184a.5.5 0 010 .707L3.89 6.719a.5.5 0 11-.707-.707l2.475-2.475-2.475-2.475A.5.5 0 113.89.355z"/></svg>
                  </span>
                </td>
                <template v-if="showTotal">
                  <td>
                    <NewRowVue
                      :num="{ name: 'ALL COMPANY TOTAL', id: 0 }"
                      :name="name.topic"
                      :company="name.company"
                      :parentId="null"
                      :subTopic="true"
                      :total="true" />
                  </td>
                  <td
                    v-for="num of sortedCompaniesVoe"
                    :key="num.id">
                    <NewRowVue
                      :num="num"
                      :name="name.topic"
                      :company="name.company"
                      :parentId="null"
                      :subTopic="true"
                      :total="false" />
                  </td>
                </template>
                <template v-else>
                  <td
                    v-for="num of sortedCompaniesVoe"
                    :key="num.id">
                    <NewRowVue
                      :num="num"
                      :name="name.topic"
                      :company="name.company"
                      :parentId="null"
                      :subTopic="true"
                      :total="false" />
                  </td>
                </template>
              </tr>
              <template v-if="!noData && name.sub_category.length">
                <div
                  class="subRows-wrapper"
                  v-if="isExpanded(name.topic_id)"
                  style="display: contents"
                  :key="'B' + index">
                  <tr
                    v-for="(category, index) of name.sub_category"
                    :key="'C' + index"
                    class="subRows">
                    <td
                      class="row-header row-header-sub tooltip-wrapper"
                      :helpText="name.helpText"
                      @mouseenter="showTooltip"
                      @mousemove="showTooltip"
                      @mouseleave="hideTooltip">
                      {{ category.topic }}
                    </td>
                    <template v-if="showTotal">
                      <td>
                      <NewRowVue
                        :num="{ name: 'ALL COMPANY TOTAL', id: 0 }"
                        :name="name.topic"
                        :company="name.company"
                        :parentId="null"
                        :subTopic="true"
                        :total="true" />
                    </td>
                    <td
                      v-for="num of sortedCompaniesVoe"
                      :key="num.id">
                      <NewRowVue
                        :num="num"
                        :name="name.topic"
                        :company="name.company"
                        :parentId="null"
                        :subTopic="true"
                        :total="false" />
                    </td>
                    </template>
                    <template v-else>
                      <td
                        v-for="num of sortedCompaniesVoe"
                        :key="num.id">
                        <NewRowVue
                        :num="num"
                        :name="category.topic"
                        :parentId="category.parent_id"
                        :company="category.company"
                        :subTopic="false"
                        :total="false" />
                      </td>
                    </template>
                  </tr>
                </div>
              </template>
            </template>
          </tbody>
        </table>
      </div>
      <div v-else>
        <NoDataContainerVue type="cSatDriver" />
      </div>
    </div>
    <div v-if="esatData.length === 0">
      <NoDataContainerVue type="cSatDriver" />
    </div>
    <!-- <div v-else>
      <NoDataContainerVue type="cSatDriver" />
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrap from '@/components/Common/DropdownWrap.vue'
import NewRowVue from '@/components/CsatDrivers/NewRow.vue'
import NoDataContainerVue from '@/components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle } from '@/constant/data'
import GlobalSortVue from '@/components/GlobalSort/GlobalSort.vue'
import tooltip from '@/util/tooltip'

/** This is the parent component/view for the **ESAT Drivers** page. */
export default {
  name: 'EsatDrivers',
  metaInfo () {
    return {
      title: metaTitle.esatDrivers
    }
  },
  components: {
    // CustomRowVue,
    NoDataContainerVue,
    GlobalSortVue,
    NewRowVue,
    DropdownWrap
  },
  data () {
    return {
      noData: false,
      dropdownOpen: false,
      parentTopics: {},
      driverViewList: [
        { name: 'By Ratings', value: 0 },
        { name: 'By Reaction', value: 1 }
      ],
      localSelected: 0,
      tooltipData: "ESAT measures include Standard attributes that are generic across companies as well as Sectoral attributes that are applicable only to a particular sector/industry."
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedCompaniesVoe',
      'sortedCompaniesVoe',
      'selectedIndustries',
      'subCategories',
      'ActiveVoeTimePeriod',
      'companyList',
      'selectedEmployeeStatuses',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('esatdriver', [
      'esatDriverSummary',
      'esatData',
      'sortOptions',
      'sort',
      'driverView',
      'showTotal'
    ]),
    ...mapState('common', ['user_preference_mode', 'currentModule']),
    ...mapState('user', ['userDetails']),
    payloadData () {
      return {
        module: this.currentModule,
        company: this.selectedCompaniesVoe.map(d => d.id),
        employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
        businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
        seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
        startTime: this.ActiveVoeTimePeriod.range[0],
        endTime: this.ActiveVoeTimePeriod.range[1],
        requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voe').module_id
      }
    },
    getName () {
      return this.driverViewList.find((option) => option.value === this.driverView).name
    },
    driverViewClass () {
      switch (this.driverView) {
        case 1:
          return 'likeView'
        default:
          return 'percentView'
      }
    },
    watchable () {
      return JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompaniesVoe) + JSON.stringify(this.ActiveVoeTimePeriod) + JSON.stringify(this.selectedEmployeeStatuses) + JSON.stringify(this.selectedBusinessFunc) + JSON.stringify(this.selectedSeniorityLevel)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getStatistics()
      }
    },
    esatDriverSummary () {
      this.setSummaryData()
    },
    sort () {
      if (this.esatDriverSummary.length) {
        this.setSummaryData()
      }
    },
    esatData () {
      if (this.esatData.length) {
        this.getListOfParentTopics()
        this.noData = false
      } else {
        this.noData = true
      }
    }
  },
  mounted () {
    if (sessionStorage.getItem('esatDriverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('esatDriverView'))
    }
    this.updateDriverDisplayMode(this.localSelected)
    this.getStatistics()
  },
  updated () {
    if (sessionStorage.getItem('esatDriverView')) {
      this.localSelected = JSON.parse(sessionStorage.getItem('esatDriverView'))
    }
    if (this.$refs.esatTableHeader) {
      const tableHeader = this.$refs.esatTableHeader
      document.querySelector('.line-separator-copy').style.setProperty('--header-height', tableHeader.clientHeight + 'px')
    }
  },
  methods: {
    ...mapActions('esatdriver', [
      'getEsatDriverSummary',
      'updateEsatDriverData',
      'updateSort',
      'updatePercentageMode',
      'updateDriverDisplayMode',
      'toggleShowTotal'
    ]),
    tooltip,
    // /** This method hides/displays the *All Company Total* column in the CSAT Drivers table
    //  * @public
    //  */
    // toggleShowTotal () {
    //   this.showTotal = !this.showTotal
    // },
    /**
     * This method updates the CSAT Driver display. It can show one of the following views:
     * - CSAT Drivers Table (Ratings View)
     * - CSAT Drivers Table (Reactions View)
     * - CSAT Drivers Kano Analysis Chart
     * @param value {Number} 0, 1, 2
     * @public
     */
    onChangeOption (value) {
      this.updateDriverDisplayMode(value)
    },
    onClickOutside (event) {
      this.dropdownOpen = false
    },
    /**
     * This methods toggles the dropdown used to select the CSAT Drivers view
     */
    openDriverViewDropdown () {
      this.dropdownOpen = !this.dropdownOpen
      if (this.dropdownOpen) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: 'Driver View Dropdown',
          pageUrl: window.location.href
        })
      }
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    /**
     * This method is used to expand the Functionality driver's hidden rows
     * @public
     */
    openFunctionality () {
      this.open = !this.open
    },
    /**
     * This method checks to see if a certain topic is expanded or not.
     * @param id {Number}
     */
    isExpanded (id) {
      return this.parentTopics[id]
    },
    /**
     * This method generates an object which contains the IDs of topics that have sub topics as keys, and the status of their expansion as a Boolean value.
     * @public
     */
    getListOfParentTopics () {
      const list = this.esatData
      const parentList = {}
      for (const item of list) {
        if (item.sub_category.length > 0) {
          if (this.parentTopics === null) { parentList[item.topic_id] = false } else { parentList[item.topic_id] = this.parentTopics[item.topic_id] }
        }
      }
      this.parentTopics = parentList
    },
    /**
     * This method is used to expand/contract the hidden rows of a parent topic
     * @param id {Number}
     */
    toggleRows (id) {
      this.parentTopics[id] = !this.parentTopics[id]
    },
    /**
     * This method gets all the data required for filling the CSAT Drivers Table
     */
    getStatistics () {
      if (
        this.currentModule === 'voe' &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.selectedCompaniesVoe.length &&
        this.ActiveVoeTimePeriod &&
        this.ActiveVoeTimePeriod.group_by
      ) {
        this.getEsatDriverSummary(this.payloadData)
        this.noData = false
      } else {
        this.noData = true
      }
      this.checkDataSelection()
    },
    /**
     * This method calculates the total values of all company drivers in the database, to create a new column titled *ALL COMPANY TOTAL* that is displayed in the CSAT Drivers Table
     * @public
     */
    addAllCompanies (arr) {
      let max = 0
      for (const feature of arr) {
        let totalCount = 0
        let cSatAvg = 0
        let dislike = 0
        let like = 0
        let count = 0
        let isStandard = false
        if (feature.company) {
          for (const company in feature.company) {
            if (feature.company[company].total_count > 0) {
              isStandard = feature.company[company].is_standard
              count++
              totalCount += feature.company[company].total_count
              cSatAvg += feature.company[company].c_sat_avg
              dislike += feature.company[company].dislike_count
              like += feature.company[company].like_count
            }
          }
          feature.company['ALL COMPANY TOTAL'] = {
            total_count: totalCount,
            c_sat_avg: cSatAvg / count,
            dislike: dislike / totalCount,
            like: like / totalCount,
            topic_name: feature.topic,
            topic_id: feature.topic_id,
            parent_id: feature.parent_id,
            is_standard: isStandard
          }
        }
        max = Math.max(max, totalCount)
      }
      for (const feature of arr) {
        if (feature.company) {
          feature.company['ALL COMPANY TOTAL'].max = max
        }
      }
      return arr
    },
    /** Unsure as to what this does */
    setSummaryData () {
      const uniqueTopics = [
        ...new Set(this.esatDriverSummary.map((item) => item.topic_id))
      ]
      let temp = 0 // {};
      for (const company of this.esatDriverSummary) {
        if (temp < Number(company.total_count)) {
          temp = Number(company.total_count)
        }
      }
      const esatData = []
      for (const topic of uniqueTopics) {
        const topicData = {
          topic,
          company: {},
          sub_category: [],
          help_text: null
        }
        for (const company of this.esatDriverSummary) {
          if (company.topic_id === topic) {
            company.max = temp
            topicData.parent_id = company.parent_id
            topicData.topic_id = company.topic_id
            topicData.topic = company.topic_name
            topicData.company[company.company_name] = company
            topicData.helpText = company.help_text
          }
        }
        esatData.push(topicData)
      }
      const withoutParentId = []
      const withParentId = []
      for (const company of esatData) {
        if (company.parent_id == null) {
          withoutParentId.push(company)
        } else {
          withParentId.push(company)
        }
      }
      if (withParentId.length) {
        for (const withoutParent of withoutParentId) {
          const child = withParentId.filter(
            (data) => data.parent_id === withoutParent.topic_id
          )
          if (child.length) {
            withoutParent.sub_category = child
          }
        }
      }
      const withOwnedCompany = []
      for (const company of withoutParentId) {
        if (company.company[this.sortedCompaniesVoe[0].name] === undefined) {
          company.company[this.sortedCompaniesVoe[0].name] = {
            c_sat_avg: 0,
            help_text: null,
            max: 0,
            dislike: 0,
            parent_id: null,
            company_name: this.sortedCompaniesVoe[0].name,
            topic_id: company.topic_id,
            topic_name: company.topic,
            total_count: 0,
            like: 0
          }
        }
        withOwnedCompany.push(company)
      }
      let finalFilter = withOwnedCompany.sort((a, b) => {
        return b.company[this.sortedCompaniesVoe[0].name][this.sort.type] >
          a.company[this.sortedCompaniesVoe[0].name][this.sort.type]
          ? this.sort.orderBy === 'asc'
            ? -1
            : 1
          : b.company[this.sortedCompaniesVoe[0].name][this.sort.type] <
            a.company[this.sortedCompaniesVoe[0].name][this.sort.type]
            ? this.sort.orderBy === 'asc'
              ? 1
              : -1
            : 0
      })
      for (const topic of finalFilter) {
        if (topic.sub_category.length) {
          for (const company of topic.sub_category) {
            if (company.company[this.sortedCompaniesVoe[0].name] === undefined) {
              company.company[this.sortedCompaniesVoe[0].name] = {
                c_sat_avg: 0,
                help_text: null,
                max: 0,
                dislike: 0,
                parent_id: null,
                company_name: this.sortedCompaniesVoe[0].name,
                topic_id: company.topic_id,
                topic_name: company.topic,
                total_count: 0,
                like: 0
              }
            }
          }
          topic.sub_category.sort((a, b) => {
            return b.company[this.sortedCompaniesVoe[0].name][this.sort.type] >
              a.company[this.sortedCompaniesVoe[0].name][this.sort.type]
              ? this.sort.orderBy === 'asc'
                ? -1
                : 1
              : b.company[this.sortedCompaniesVoe[0].name][this.sort.type] <
                a.company[this.sortedCompaniesVoe[0].name][this.sort.type]
                ? this.sort.orderBy === 'asc'
                  ? 1
                  : -1
                : 0
          })
        }
      }

      finalFilter = this.addAllCompanies(finalFilter)
      for (const subTopic of finalFilter) {
        if (subTopic.sub_category && subTopic.sub_category.length) {
          subTopic.sub_category = this.addAllCompanies(subTopic.sub_category)
        }
      }
      this.updateEsatDriverData(finalFilter)
    },
    /**
     * This method makes sure that the minimum data needed to create the table/chart is present. If it's not, then the *No Data* container is shown instead.
     * @public
     */
    checkDataSelection () {
      if (
        !(
          this.selectedCompaniesVoe.length &&
          this.selectedEmployeeStatuses.length
        )
      ) {
        this.updateEsatDriverData([])
        this.noData = true
      }
    },
    /**
     * This method shows the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    showTooltip (event, d) {
      this.tooltipFun(event, d, 'in')
    },
    /**
     * This method hides the tooltip. Intended for the CSAT Drivers table. Currently not in use
     */
    hideTooltip (event, d) {
      this.tooltipFun(event, d, 'out')
    },
    /**
     * This method controls the content shown in the tooltip
     */
    tooltipFun (event, d, type) {
      let data = ''
      switch (type) {
        case 'in':
          data = event.target.getAttribute('helptext')
          break
      }
      if (data || type === 'out') {
        tooltip(event, data, type, true)
      }
    }
  }
}
</script>
<style lang="scss" src="./ESatDrivers.scss" scoped />
