<template>
  <div :class="[`row-for-product`, driverViewClass]">
    <div class="row-data-block">
      <!-- <ProgressVue
        @onChange="onChange(num.name)"
        :key="1"
        v-if="
          items[num.name] &&
          items[num.name]['total_count'] > 0
        "
        :noReview="false"
        :value="items[num.name]['total_count']"
        :max="items[num.name]['max']" /> -->
      <ProgressVue
        @onChange="onChange(num.name)"
        :key="1"
        v-if="
          items[num.name] &&
          items[num.name]['total_count'] > 0
        "
        :progressColor="wellLeveraged"
        :noReview="false"
        :value="Number(items[num.name]['c_sat_avg']).toFixed(1)"
        :max="5"
        :pointer="showPointer"
        :total="total" />
      <ProgressVue :key="2" v-else :noReview="true" :value="0" :max="0" :pointer="showPointer" :total="total" />
    </div>
      <div
        v-if="
          items[num.name] &&
          items[num.name]['total_count'] > 0
        "
        class="row-data-block-small">
        <div class="importance-style">
          <ul v-if="driverViewClass === 'likeView'">
            <div class="like-dislike">
              <div @click="onChange(num.name,[{name: 'Like',id: 'POSITIVE'}])" class="like-bar">
                <p>{{Math.round(items[num.name]['like']*100)+'%'}}</p>
            <b-progress  class="like-progress" :value="items[num.name]['like']*100"></b-progress>
              </div>
              <div @click="onChange(num.name,[{name: 'Dislike',id: 'NEGATIVE'}])" class="dislike-bar">
                <p>{{Math.round(items[num.name]['dislike']*100)+'%'}}</p>
            <b-progress  class="dislike-progress" :value="items[num.name]['dislike']*100"></b-progress>
              </div>
            </div>
          </ul>
          <ul
            v-if="driverViewClass !== 'likeView'"
            @mouseenter="showData(name, num.name)"
            @mouseleave="showData('', '')">
            {{
              Number(items[num.name]['total_count'])
            }}
          </ul>
        </div>
      </div>
      <div v-else class="row-data-block-small">
        <div class="importance-style">
          <ul>
            {{
              'N/A'
            }}
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProgressVue from './Progress.vue'
export default {
  name: 'NewRow',
  components: {
    ProgressVue
  },
  props: ['name', 'products', 'company', 'subTopic', 'parentId', 'num', 'total'],
  data () {
    return {
      item: '',
      topic: '',
      topic_name: '',
      item_name: ''
    }
  },
  computed: {
    ...mapState('filters', ['sortedProducts', 'selectedProducts', 'selectedCompaniesVoe', 'selectedCsatVendors']),
    ...mapState('esatdriver', {
      driverViewVoE: state => state.driverView
    }),
    ...mapState('csatdriver', {
      driverViewVoC: state => state.driverView,
      csatVendorDriverView: state => state.csatVendorDriverView
    }),
    ...mapState('common', ['currentModule', 'active_page']),
    items () {
      if (this.currentModule === 'voc' && this.active_page !== 'vendor-csat') {
        return this.products
      } else {
        return this.company
      }
    },
    driverView () {
      if (this.currentModule === 'voc') {
        if (this.active_page === 'vendor-csat') return this.csatVendorDriverView
        return this.driverViewVoC
      } else {
        return this.driverViewVoE
      }
    },
    driverViewClass () {
      switch (this.driverView) {
        case 1:
          return 'likeView'
        case 2:
          return 'comp-comparison'
        default:
          return 'percentView'
      }
    },
    showPointer () {
      if (this.active_page === 'vendor-csat') {
        return false
      }
      return true
    },
    wellLeveraged () {
      if (this.driverView === 0 && this.currentModule === 'voc') {
        if (this.active_page !== 'vendor-csat') {
          if (this.items[this.num.name] !== this.items['ALL PRODUCT TOTAL']) {
            if (Number(this.items[this.num.name].c_sat_avg).toFixed(1) >= Number(this.items['ALL PRODUCT TOTAL'].c_sat_avg).toFixed(1)) { return 'well-leveraged' } else { return 'under-leveraged' }
          }
        } else {
          if (this.items[this.num.name] !== this.items['ALL COMPANY TOTAL']) {
            if (Number(this.items[this.num.name].c_sat_avg).toFixed(1) >= Number(this.items['ALL COMPANY TOTAL'].c_sat_avg).toFixed(1)) { return 'well-leveraged' } else { return 'under-leveraged' }
          }
        }
      } else if (this.driverView === 0 && this.currentModule === 'voe') {
        if (this.items[this.num.name] !== this.items['ALL COMPANY TOTAL']) {
          if (Number(this.items[this.num.name].e_sat_avg).toFixed(1) >= Number(this.items['ALL COMPANY TOTAL'].e_sat_avg).toFixed(1)) { return 'well-leveraged' } else { return 'under-leveraged' }
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions('filters', ['updateSelectedProducts', 'updateSelectedFeatures', 'updateUserType', 'updateSelectedCompaniesVoe', 'updateSelectedCsatVendors']),
    showData (topic, item) {
      this.topic = topic
      this.item = item
    },
    onChange (item, reactions = []) {
      if (this.currentModule === 'voc') {
        if (this.active_page !== 'vendor-csat') {
          const findProduct = this.selectedProducts.filter(({ name }) => name === item)
          this.updateSelectedFeatures([{ id: this.items[item].topic_id, name: this.items[item].topic_name, parent_id: this.parentId, is_standard: this.items[item].is_standard }])
          if (findProduct.length) {
            this.updateSelectedProducts([findProduct[0]])
          }
          if (reactions.length) {
            this.updateUserType(reactions)
          }
          this.$router.push({ name: 'comments' })
        }
      } else if (this.currentModule === 'voe') {
        const findCompany = this.selectedCompaniesVoe.filter(({ name }) => name === item)
        this.updateSelectedFeatures([{ id: this.items[item].topic_id, name: this.items[item].topic_name, parent_id: this.parentId, is_standard: this.items[item].is_standard }])
        if (findCompany.length) {
          this.updateSelectedCompaniesVoe([findCompany[0]])
        }
        if (reactions.length) {
          this.updateUserType(reactions)
        }
        this.$router.push({ name: 'voeComments' })
      }
    },
    calImportanceColor (type, val) {
      switch (type) {
        case 'first':
          if (val > 0) return true
          break
        case 'second':
          if (val >= 50 && val < 80) return true
          break
        case 'third':
          if (val >= 80 && val <= 100) return true
          break
      }
    },
    getImportaneType (num) {
      if (num >= 80) {
        return 'High'
      } else if (num >= 50 && num < 80) {
        return 'Medium'
      } else {
        return 'Low'
      }
    },
    showDataOfCsat (topic, item) {
      this.topic_name = topic
      this.item_name = item
    }
  }
}
</script>

<style lang="scss" scoped>

  .row-for-product, .row-for-company {
    display: flex;
    padding: 0 15px;
    width: max-content;
    border-right: 1px solid var(--c-sat-driver-table-border);
    &:last-of-type {
      border-right: none;
    }
    :where(.row-data-block, .row-data-block-small) {
      display: flex;
      align-items: center;
      padding: 15px 0px;
      width: max-content;
      position: relative;
      .tooltip-importance {
        position: absolute;
        background: #5aa4ee 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        opacity: 1;
        display: flex;
        justify-content: center;
        width: 130px;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        height: 30px;
        align-items: center;
        p {
          text-align: left;
          font-size: 16px;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
          margin: 0px;
        }
      }
      .importance-style {
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        .like-dislike {
          display: flex;
          flex-direction: column;
          p {
            padding-right: 5px;
            font-size: 10px;
            font-family: 'ROBOTO';
          }
          .like-bar {
            display: flex;
            align-items: center;
          }
          .dislike-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
        .tooltip-importance {
          position: absolute;
          background: #5aa4ee 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 4px;
          opacity: 1;
          display: flex;
          justify-content: center;
          width: 170px;
          left: 50%;
          transform: translateX(-50%);
          top: -35px;
          height: 30px;
          align-items: center;
          p {
            text-align: left;
            font-size: 16px;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin: 0px;
          }
        }
        ul {
          list-style-type: none;
          display: flex;
          padding: 0px;
          margin: 0px;
          align-items: center;
          color: var(--secondary-text-color);
          font-size: 12px;

          li {
            width: 10px;
            margin-right: 3px;
            height: 10px;
            background: #f2f2f6;
            border-radius: 4px;
            opacity: 1;
          }
          .active {
            background: #5aa4ee;
          }
          p {
            padding-left: 5px;
          }
        }
        .well-leveraged {
          color: var(--green);
        }

        .under-leveraged {
          color: var(--yellow);
        }
      }
      .company-logo {
        opacity: 1;
      }
      .progress {
        width: 100%;
        background-color: #f2f2f6;
        cursor: pointer;
      }
    }
    .width-full {
      // width: 100%;
      padding-bottom: 20px;
    }
  }

.row-data-block {
  // width: 90%;
  margin-left: 4px;
  margin-right: 4px;
}
.row-data-block-small {
  // width: 10%;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  font-family: 'Quicksand';
}
</style>
